<template>
  <div class="avatar-page">
    <iframe
      id="createAvatar"
      v-if="!avatarUrl"
      class="frame"
      ref="frame"
      :src="frameUrl"
      allow="camera *; microphone *; clipboard-write"
    />
    <div v-else class="avatar-message">
      <div v-if="!block">
        <v-icon size="100" :color="color">{{ icon }}</v-icon>

        <h1>
          {{
            authorized
              ? "Your avatar has been created"
              : "Error. User unauthorized!"
          }}
        </h1>
        <h3>You can go back to Unity app</h3>
      </div>

      <v-fade-transition>
        <v-overlay :value="block" color="#FFFFFF">
          <p class="loading-text">Please, wait ...</p>
          <v-progress-linear color="#1EAFC1" indeterminate size="50" />
        </v-overlay>
      </v-fade-transition>
    </div>
  </div>
</template>

<script>
import decoder from "jwt-decode";
//import awsAuth from "@/cognito/aws-auth";

import loader from "@/config.loader";
import {mapActions} from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      step: "select",
      metadata: [],
      avatarUrl: null,
      created: false,
      userName: null,
      authorized: false,
      icon: "far fa-check-circle",
      color: "#56CEDD",

      block: false,
      token: '',
    };
  },

  mounted() {
    console.log("start");
    // let header = document.getElementsByTagName('header')[0];
    // header.style = 'display: none;'

    window.addEventListener("message", this.receiveMessage, false);
    document.addEventListener("message", this.receiveMessage, false);

    this.checkToken();
    this.getAllCookies(this.$refs.frame);
  },

  computed: {
    frameUrl() {
      return loader.getConfigValue("VUE_APP_W3_AVATAR_URL");
    },
  },

  methods: {
    ...mapActions("ui", ["snackbar"]),
    async checkToken() {
      const guid = this.$route.params.guid;
      const apiURL = loader.getConfigValue("RENDEZVU_API");

      const options = {
        url: `${apiURL}/token/getToken?guid=${guid}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*'
        },
      }

      axios(options)
        .then(async response => {
          this.token = response.data.token;

          await fetch(`${apiURL}/users/checkToken`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          })
            .then((response) => {
              if (!response.ok) {
                this.avatarUrl = "error";
                this.authorized = false;
                this.icon = "fa fa-exclamation-circle";
                this.color = "#DC143C";
              } else {
                const decodeToken = decoder(this.token);
                this.userName = decodeToken["username"];
                const issuer = new URL(decodeToken["iss"]);
                fetch(`${issuer.protocol}//${issuer.host}`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/x-amz-json-1.1',
                    'X-Amz-Target': 'AWSCognitoIdentityProviderService.GetUser'
                  },
                  body: JSON.stringify({ AccessToken: this.token })
                }).then((response) => {
                  console.log(response)
                  return response.json();
                }).then((data) => {
                  console.log('data', data.UserAttributes[2])
                  let meta = data.UserAttributes[2] ? JSON.parse(data.UserAttributes[2].Value).Items : []
                  this.metadata = meta
                  console.log('Metadata', this.metadata)
                })
                this.authorized = true;
              }
            })
            .catch((error) => {
              console.log(error);
              this.avatarUrl = "error";
              this.authorized = false;
              this.icon = "fa fa-exclamation-circle";
              this.color = "#DC143C";
            });

        })
        .catch(err => {
          this.snackbar({
            color: "error",
            icon: "mdi-alert-circle-outline",
            title: err.response.data.message,
            text: 'Please go back to RendezVu app and try again',
            timeout: -1
          });

          this.avatarUrl = "error";
          this.icon = "fa fa-exclamation-circle";
          this.color = "#DC143C";
        })
    },

    getAllCookies(element) {
      console.log(document.cookie);
      console.log(element);
      var cookies = document.cookie.split(";");

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },

    receiveMessage(event) {

      function parseEvent() {
        try {
          return JSON.parse(event.data);
        } catch (error) {
          return null;
        }
      }

      const json = parseEvent();

      if (json?.source !== 'readyplayerme') {
        return;
      }

      // Susbribe to all events sent from Ready Player Me once frame is ready
      if (json.eventName === 'v1.frame.ready') {
        console.log('[RPM] frame ready, subscribing to all events');
        document.getElementById('createAvatar').contentWindow.postMessage(
          JSON.stringify({
            target: 'readyplayerme',
            type: 'subscribe',
            eventName: 'v1.**'
          }),
          '*'
        );
      }

      // avatar exported
      if (json.eventName === 'v1.avatar.exported') {
        this.avatarUrl = json.data.url;
        console.log(`[RPM] Avatar URL: ${this.avatarUrl}`);
        this.updatedUserData(this.avatarUrl);  
      }
    },

    async updatedUserData(avatarUrl) {
      function makeid(length) {
        let result = "";
        const characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
          result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
        }
        return result;
      }

      this.block = true;

      const apiURL = loader.getConfigValue("RENDEZVU_API");
      console.log(`Saving avatar from url: ${avatarUrl}, status: ${this.created}`);
      
      let length = this.metadata.length;
      if (!this.created && avatarUrl) {
        this.created = true;

        // raw avatar & json url without https://
        const fileUrl = avatarUrl;
        const jsonUrl = avatarUrl.replace(".glb", ".json");

        const newUid = makeid(30);
        const newFileName = newUid + '.glb';
        const newJsonFileName = newUid + '.json';

        fetch(`${apiURL}/users/uploadAvatar`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.token}`,
          },
          body: JSON.stringify({
            fileName: newJsonFileName,
            url: jsonUrl
          }),
        });

        const newURL = await fetch(`${apiURL}/users/uploadAvatar`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.token}`,
          },
          body: JSON.stringify({
            fileName: newFileName,
            url: fileUrl,
          }),
        })
          .then((r) => r.json())
          .then((r) => r.data.Location);

        avatarUrl = newURL;

        this.created = true;

        console.log(`New avatar url: ${avatarUrl}, status: ${this.created}`);

        // console.log("Updating player metadata: ", this.metadata);

        if (length != 0) {
          if (length != 3) {
            this.metadata = [
              ...this.metadata,
              { FeaturesType: 1, Features: JSON.stringify({ Url: avatarUrl }) },
            ];
          } else {
            //let data = this.dataMove(this.metadata, (length - 1), 0);
            this.metadata.shift();
            this.metadata = [
              ...this.metadata,
              { FeaturesType: 1, Features: JSON.stringify({ Url: avatarUrl }) },
            ];
          }
        } else {
          this.metadata[0] = {
            FeaturesType: 1,
            Features: JSON.stringify({ Url: avatarUrl }),
          };
        }

        // console.log("after metadata: ", this.metadata);

        const body = {
          attributes: [
            {
              Name: "custom:avatarmetadata",
              Value: JSON.stringify({ Items: this.metadata }),
            },
          ],
        };

        console.log(`Updaing player ${this.userName} metadata`);

        await fetch(
          `${apiURL}/users/updateAttributes?userName=${this.userName}&pool=user`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.token}`,
            },
            body: JSON.stringify(body),
          }
        );

        this.block = false;
      }
    },
    dataMove(arr, oldIndex, newIndex) {
      if (newIndex >= arr.length) {
        var k = newIndex - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
      return arr; // for testing
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-page {
  display: flex;

  width: 100%;
  height: 100%;
}

.frame,
.avatar-message {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  z-index: 1000;

  border: none;
}

.avatar-message {
  background-color: #f5f5f5;
  color: #292d44;

  display: grid;
  place-content: center;

  text-align: center;

  .v-icon {
    margin: 2rem;
  }
}

.loading-text {
  font-size: 1.25em;

  color: #1eafc1;
}
</style>
